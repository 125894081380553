import faqLists from './faqLists';
// eslint-disable-next-line no-restricted-imports
import guidePdf from '../../assets/downloads/guide.pdf';
import styles from 'templates/frequently-asked-questions/frequentlyAskedQuestions.module.scss';
import Container from 'components/Container/Container';
import ReakitSeparator from 'components/Separator/Separator';
import Layout from 'components/Layout/Layout';
import React, { Fragment } from 'react';
import Heading from '@moonshineragency/ui/src/components/Heading/Heading';
import Content from '@moonshineragency/ui/src/components/Content/Content';
import { Link } from '@moonshineragency/ui/src/components/Link/Link';

const Faq = () => {
  const encodedEmail = (
    <Link
      target="_blank"
      variant="noUnderline"
      href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#99;&#97;&#114;&#111;&#108;&#121;&#110;&#64;&#115;&#99;&#111;&#112;&#101;&#45;&#115;&#99;&#105;&#46;&#111;&#114;&#103;"
    >
      &#99;&#97;&#114;&#111;&#108;&#121;&#110;&#64;&#115;&#99;&#111;&#112;&#101;&#45;&#115;&#99;&#105;&#46;&#111;&#114;&#103;
    </Link>
  );

  const textWithTrialLink = (
    <Content size="copy100">
      In general, only trials are shown that are listed on the governmental
      database{' '}
      <Link
        target="_blank"
        variant="noUnderline"
        href="https://clinicaltrials.gov/"
      >
        www.clinicaltrials.gov
      </Link>
      . There are other databases around the world but clinicaltrials.gov is the
      largest. We <strong>do not</strong> provide curations for private/unproven
      treatments, trials without ethical approval or trials which ask for money.
    </Content>
  );
  const textWithCurateEmail = (
    <Content size="copy100">
      Most likely, we have not yet had time to curate your trial. Please send us
      an email to {encodedEmail} and our team will look into this.
    </Content>
  );
  const textWithUpdateEmail = (
    <Content size="copy100">
      Please send us an email to {encodedEmail} and our team will check and
      update the information.
    </Content>
  );
  const textWithAnalyzeEmail = (
    <Content size="copy100">
      Please click on{' '}
      <Link variant="noUnderline" href="/trials">
        “All trials”
      </Link>
      . You can then filter by trial status, trial type, and potential benefits
      and download a csv file of all filtered trials to analyze the meta data.
      If you need help or more information, please send us an email to{' '}
      {encodedEmail}.
    </Content>
  );
  const textWithGuideLink = (
    <Content size="copy100">
      Please have a look at this{' '}
      <Link variant="noUnderline" href={guidePdf} target="_blank">
        guide
      </Link>{' '}
      about clinical trials. Here you’ll find lots of valuable information on
      topics such as what makes a good clinical trial and what you should
      consider if you want to take part. A participation checklist is available
      in the guide.
    </Content>
  );
  const textWithAsiaScaleLink = (
    <Content size="copy100">
      The{' '}
      <Link
        variant="noUnderline"
        href="https://asia-spinalinjury.org/international-standards-neurological-classification-sci-isncsci-worksheet/"
        target="_blank"
      >
        American Spinal Injury Association Impairment Scale
      </Link>{' '}
      classifies five levels. They range from complete loss of neural function
      in the affected area to completely normal:
    </Content>
  );

  return (
    <Layout
      title="FAQ spinal cord injury trials"
      description="Frequently asked questions about spinal cord injury trials and SCI Trials Finder."
      onlyShowSearchIcon
      isContentpage
    >
      <Container size="lg">
        <div className={styles.faqContainer}>
          <Heading size="h1" theme="primary">
            FAQ – Frequently Asked Questions
          </Heading>
          <ul className={styles.faqTitleLists}>
            {faqLists.map(list => {
              return (
                <li key={list.title} className={styles.faqTitleList}>
                  <Link
                    asType="internal"
                    size="100"
                    type="text"
                    href={`#${list.linkId}`}
                    className={styles.faqTitleLink}
                  >
                    {list.title}
                  </Link>
                </li>
              );
            })}
          </ul>
          <ReakitSeparator />
          {faqLists.map(list => {
            return (
              <Fragment key={list.title}>
                <div className={styles.faqContent}>
                  <div id={list.linkId} className={styles.linkSpan} />
                  <Heading
                    theme="primary"
                    className={styles.faqListHeading}
                    size="h3"
                  >
                    {list.title}
                  </Heading>
                  <Content size="copy100" noSpacing>
                    {list.content && list.content}
                    {list.textWithGuideLink === '' && textWithGuideLink}
                    {list.textWithAsiaScaleLink === '' && textWithAsiaScaleLink}
                    {list.textWithTrialLink === '' && textWithTrialLink}
                    {list.textWithCurateEmail === '' && textWithCurateEmail}
                    {list.textWithUpdateEmail === '' && textWithUpdateEmail}
                    {list.textWithAnalyzeEmail === '' && textWithAnalyzeEmail}
                  </Content>
                  {list.textArray &&
                    list.textArray.map(el => {
                      return (
                        <Content size="copy100" noSpacing key={el.id}>
                          <div className={el.section ? styles.mainSection : ''}>
                            {el.section}
                          </div>
                          <ul className={styles.listContainer}>
                            {el.sub_section?.map(item => (
                              <li
                                className={styles.sub_section_list}
                                key={item}
                              >
                                {item}
                              </li>
                            ))}
                          </ul>
                        </Content>
                      );
                    })}
                </div>
                <div className={styles.separator}>
                  <ReakitSeparator />
                </div>
              </Fragment>
            );
          })}
        </div>
      </Container>
    </Layout>
  );
};

export default Faq;
